/* eslint-disable require-await */
import createAuth0Client, { Auth0Client, type Auth0ClientOptions } from '@auth0/auth0-spa-js';
import 'fast-text-encoding';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import type { PromiseReturn } from '../../common/util/type-utils';
import type { Memoized } from '../util/react-memo-util';

export type GetTokenSilently = Memoized<Auth0Client['getTokenSilently']>;

type Auth0Context = {
  isAuthenticated: boolean;
  isEmployeeLogin: boolean;
  isAdmin: boolean;
  getTokenSilently: GetTokenSilently;
  loading: boolean;
  loginWithRedirect: Auth0Client['loginWithRedirect'];
  logout: Auth0Client['logout'];
  user: any | null;
};

export const Auth0Context = React.createContext(null as unknown as Auth0Context);
export const useAuth0 = () => useContext(Auth0Context);

type State = {
  stage: 'loading' | 'success' | 'error';
  context?: PromiseReturn<typeof initAuth0>;
  error?: Error;
};

function onRedirectCallback(appState: any) {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  );
  if (appState && appState.targetUrl && !appState.targetUrl.startsWith(window.location.origin)) {
    window.location.href = appState.targetUrl;
  }
}

export function Auth0Provider(props: { children: JSX.Element } & Auth0ClientOptions) {
  const { children, ...initOptions } = props;
  const defaultState = { stage: 'loading' } as State;
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    if (state.stage === 'loading') {
      initAuth0(initOptions)
        .then((reply) => dispatch({ type: 'load::ok', payload: reply }))
        .catch((error) => dispatch({ type: 'load::error', error }));
    }
  }, [state]);

  const context = useMemo(() => contextFromState(contextDefault(), state), [state]);

  return <Auth0Context.Provider value={context}>{children}</Auth0Context.Provider>;
}

function contextDefault(): Auth0Context {
  const throwNotReady = (): any => {
    throw new Error('Attempted to call Auth0Context before initialization');
  };

  return {
    isAuthenticated: false,
    isEmployeeLogin: false,
    isAdmin: false,
    getTokenSilently: (() => throwNotReady()) as GetTokenSilently,
    loading: true,
    loginWithRedirect: () => throwNotReady(),
    logout: () => throwNotReady(),
    user: null,
  };
}

/**
 * Convert the given state object into a context object.
 */
function contextFromState(defaultContext: Auth0Context, state: State) {
  switch (state.stage) {
    case 'success':
      return {
        ...defaultContext,
        ...state.context,
        loading: false,
      };

    case 'error':
      console.error('Failed to initialize authentication:', state.error);

      throw new Error(`
        'Authentication Initialization Failed

        error: ${JSON.stringify(state.error)}
      `);

    case 'loading':
    default:
      return defaultContext;
  }
}

/**
 * Initialize Auth0
 */

async function initAuth0(options: Auth0ClientOptions) {
  const client = await makeAuth0Client(options);
  const { search } = window.location;

  if (search.includes('code=') && search.includes('state=')) {
    const { appState } = await client.handleRedirectCallback();
    onRedirectCallback(appState);
  }

  let user = null;
  const isAuthenticated = await client.isAuthenticated();
  let isEmployeeLogin = false;
  let isAdmin = false;

  if (isAuthenticated) {
    const [_user, token] = await Promise.all([client.getUser(), client.getTokenSilently()]);
    user = _user;
    const claims = JSON.parse(atob(token.split('.')[1]));
    isAdmin = !!claims.permissions?.includes('write:admin');
    isEmployeeLogin = !!user?.sub?.startsWith('google-apps|');
  }

  return {
    isAuthenticated,
    isEmployeeLogin,
    isAdmin,
    getTokenSilently: client.getTokenSilently as GetTokenSilently,
    loading: false,
    loginWithRedirect: client.loginWithRedirect,
    logout: client.logout,
    user,
  };
}

async function makeAuth0Client(options: Auth0ClientOptions) {
  const client = await createAuth0Client(options);

  /**
   * The following functions are extracted so that we can add tracking to the call usage when
   * we encounter issues with authentication.
   */
  return {
    async handleRedirectCallback(...p: Parameters<typeof client.handleRedirectCallback>) {
      return client.handleRedirectCallback(...p);
    },
    async getTokenSilently(...p: Parameters<typeof client.getTokenSilently>) {
      return client.getTokenSilently(...p);
    },
    async getUser(...p: Parameters<typeof client.getUser>) {
      return client.getUser(...p);
    },
    async isAuthenticated(...p: Parameters<typeof client.isAuthenticated>) {
      return client.isAuthenticated(...p);
    },
    async loginWithRedirect(...p: Parameters<typeof client.loginWithRedirect>) {
      return client.loginWithRedirect(...p);
    },
    async logout(...p: Parameters<typeof client.logout>) {
      return client.logout(...p);
    },
  };
}

/**
 * State reducer to handle profile loading events
 */
function reducer(
  state: State,
  action:
    | { type: 'load::ok'; payload: PromiseReturn<typeof initAuth0> }
    | { type: 'load::error'; error: Error },
): State {
  switch (action.type) {
    case 'load::ok':
      return {
        ...state,
        context: action.payload,
        stage: 'success',
      };

    case 'load::error':
      return {
        ...state,
        error: action.error,
        stage: 'error',
      };

    default:
      return state;
  }
}
