/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React, { Suspense, lazy } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, type RouteComponentProps } from 'react-router-dom';
import { hasManagerPermissions } from '../common/permissions';
import { NotUndefined } from '../common/util/type-utils';
import { SideNavPageProps_Alternate, SideNavPageProps_Normal } from './components/_base/layout/Layout';
import css from './components/app-layout/App.module.css';
import { AppContext, _useMakeAppContext, useAppContext } from './components/app-layout/AppContext';
import AppDialogs from './components/app-layout/AppDialogs';
import Loading from './components/plusComponents/Loading';
import { WhichPortalContextProvider } from './context/useWhichPortal';
import { usePartialLoad } from './hooks/partial-loading';
import type { ManagerProfileContext, ManagerProfileContextLoadingOrError } from './libraries/managerProfile';
import { useManagerProfile } from './libraries/managerProfile';
import { useAuth0 } from './libraries/react-auth0-spa';
import { AppSnackbars } from './libraries/snackbar';
import LandingPage from './pages/Landing';
import LoginSaml from './pages/LoginSaml';
import { useMakeContextTypical } from './util/react-memo-util';

// eslint-disable-next-line no-unused-expressions
css; // prevent autoremoval of import by VSCode "Organize Imports" feature

const CaseStudyPage = lazy(() => import('./pages/case-studies/[slug]'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const AcceptableUsePolicy = lazy(() => import('./pages/AcceptableUsePolicy'));
const BlogPage = lazy(() => import('./pages/blog'));
const BlogPost = lazy(() => import('./pages/blog/[slug]'));

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);

const Layout = lazy(() => import('./components/_base/layout/Layout'));
const Redirector = lazy(() => import('./pages/Redirector'));
const Admin = lazy(() => import('./pages/Admin'));
const AdminProjectDetails = lazy(() => import('./pages/AdminProjectDetails'));
const APIResponse = lazy(() => import('./pages/APIResponse'));

const LoginAs = lazy(() => import('./pages/LoginAs'));
const UserNotFound = lazy(() => import('./pages/UserNotFound'));
const ErrorSwitchTeam = lazy(() => import('./pages/ErrorSwitchTeam'));
const DashboardSwitch = lazy(() => import('./components/plusComponents/home/DashboardSwitch'));
const Tasks = lazy(() => import('./components/plusComponents/Tasks/Tasks'));
const ErrorCenter = lazy(() => import('./components/plusComponents/ErrorCenter/ErrorCenter'));
const BidRoom = lazy(() => import('./components/plusComponents/BidRoom/BidRoom'));
const Onboard = lazy(() => import('./pages/Onboard'));
const PlaidOnboardBuilding = lazy(() => import('./pages/PlaidOnboardBuilding'));
const Reports = lazy(() => import('./components/plusComponents/Reports'));
const Vendors = lazy(() => import('./components/plusComponents/Vendor/Vendors'));
const VendorDetail = lazy(() => import('./components/plusComponents/Vendor/VendorDetails'));
const VendorSettings = lazy(() => import('./components/plusComponents/Vendor/VendorSettings'));
const DataValidation = lazy(() => import('./components/plusComponents/DataValidation/DataValidation'));
const VendorDashboard = lazy(() => import('./components/plusComponents/Vendor/VendorDashboard'));
const VendorProject = lazy(() => import('./components/plusComponents/Vendor/VendorProject'));
const ContractRoom = lazy(() => import('./components/plusComponents/ContractRoom/ContractRoom'));
const Settings = lazy(() => import('./components/plusComponents/Settings/Settings'));
const ProjectShell = lazy(() => import('./components/plusComponents/ProjectShell/ProjectShell'));
const ApprovalPage = lazy(() => import('./components/plusComponents/ApprovalPage'));
const DrawReportPortal = lazy(() => import('./components/plusComponents/DrawReportPortal/DrawReportPortal'));
const LoginRestricted = lazy(() => import('./pages/LoginRestricted'));
const Staff = lazy(() => import('./pages/Staff'));

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return authed === true ? (
          <Component {...props}>{props.children}</Component>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
}

function PrivatePlusRoute(props: {
  computedMatch?: import('react-router-dom').match<any>; // not clear how these are added, must be related to react router <Switch>
  location?: import('history').Location; // not clear how these are added, must be related to react router <Switch>
  authed: boolean;
  component: React.FC<SideNavPageProps_Normal> | React.FC<SideNavPageProps_Alternate>;
  path: NotUndefined<import('react-router-dom').RouteProps['path']>;
  vendorLogin?: boolean;
}) {
  const { component: Component, authed, vendorLogin, ...rest } = props;
  const { logout } = useAuth0();
  const manager = useManagerProfile() as ManagerProfileContext | ManagerProfileContextLoadingOrError;
  const partialLoadHandler = usePartialLoad();

  const render = (props: RouteComponentProps) => {
    if (!authed) {
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }

    const errorMessage = manager?.error?.response?.data?.message;
    if (errorMessage === 'Forbidden: Selected Team is not valid') {
      return (
        <Redirect
          to={{
            pathname: '/plus/switchError',
            state: { from: props.location },
          }}
        />
      );
    }
    if (!manager?.profile && !vendorLogin) {
      return <UserNotFound logout={logout} message={errorMessage} />;
    }

    return (
      <Layout page={props.location.pathname} partialLoadHandler={partialLoadHandler} {...props}>
        {/* @ts-ignore: missing props added by Layout.tsx using React.cloneElement */}
        <Component {...props} />
      </Layout>
    );
  };

  const isVendorPortal = Boolean(vendorLogin);
  const isOwnerPortal = !vendorLogin;

  const whichPortalCtx = useMakeContextTypical({ isOwnerPortal, isVendorPortal });

  return (
    <WhichPortalContextProvider value={whichPortalCtx}>
      <Route {...rest} render={render} />
    </WhichPortalContextProvider>
  );
}

const loadingElement = <Loading open message="Building something awesome for you..." />;

export default function App() {
  const { context, snackbarsImplementationRef, dialogFnRef, bottomrightRef } = _useMakeAppContext();
  const manager = context.managerProfile as ManagerProfileContext | ManagerProfileContextLoadingOrError;
  const { isAuthenticated, isEmployeeLogin, user, loading } = useAuth0();

  if (loading || (manager.isLoading && isAuthenticated)) {
    return loadingElement;
  }

  const search = window.location.search && new URLSearchParams(window.location.search);
  if (search && search.get('error') === 'access_denied') {
    return (
      <AppContext.Provider value={context}>
        <Suspense fallback={loadingElement}>
          <LoginRestricted search={search} />
        </Suspense>
      </AppContext.Provider>
    );
  }

  const defaultRedirect = hasManagerPermissions(user) ? (
    <Redirect to="/plus/dashboard" />
  ) : (
    <Redirect to="/plus/vendors" />
  );

  // Adding a globally stored flag to ensure that the `auth0DidLoad` event is
  // triggered once, and only once.  If the `auth0DidLoad` is fired multiple
  // times then the heap.io library is loaded multiple times.  The multiple
  // loads of the heap.io library triggers the an uncaught exception.
  // https://help.heap.io/getting-started/getting-started-faqs/heap-push-is-not-a-function-console-error/
  if (!process.env.DISABLE_GOOGLETAG && !window.__banner?.GOOGLE_TAG_LOADED && !loading) {
    // Utilize the Google TagManager data layer in accordance to the documentation.
    // https://developers.google.com/tag-platform/tag-manager/web/datalayer
    window.dataLayer = window.dataLayer || [];

    let loadEvent = {
      email: undefined,
      event: 'auth0DidLoad',
    };

    if (user) {
      loadEvent = {
        ...loadEvent,
        email: user.email,
        companyName: manager?.profile?.companyName,
        isAdmin: manager?.profile?.isAdmin,
        isManager: hasManagerPermissions(user),
        teamID: manager?.profile?.team?._id,
        teamName: manager?.profile?.team?.name,
        userID: manager?.profile?._id,
        userName: manager?.profile?.individualName,
      };
    }

    window.dataLayer.push(loadEvent);
    window.__banner = window.__banner || {};
    window.__banner.GOOGLE_TAG_LOADED = true;
  }

  return (
    <AppContext.Provider value={context}>
      <Router>
        {!loading && (
          <Suspense fallback={loadingElement}>
            <Switch>
              {isEmployeeLogin && (
                <>
                  <Route path="/staff" component={Staff} />
                  <Route path="*">
                    <Redirect
                      to={{
                        pathname: '/staff',
                        search: window.location.search,
                      }}
                    />
                  </Route>
                </>
              )}

              <Route path="/auth0/saml">
                <LoginSaml />
              </Route>
              <Route exact path="/">
                {isAuthenticated ? defaultRedirect : <LandingPage />}
              </Route>
              <Route exact path="/case-studies/:slug">
                <CaseStudyPage />
              </Route>
              <Route exact path="/blog">
                <BlogPage />
              </Route>
              <Route exact path="/blog/:slug">
                <BlogPost />
              </Route>
              <Route exact path="/plus">
                {isAuthenticated ? defaultRedirect : <LandingPage />}
              </Route>
              <Route exact path="/terms">
                <TermsOfUse />
              </Route>
              <Route exact path="/privacy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/acceptable">
                <AcceptableUsePolicy />
              </Route>

              <Route path="/plus" component={Plus} isAuthenticated />
              <Route exact path="/redirect" component={Redirector} />
              <Route exact path="/response" component={APIResponse} />
              <Route exact path="/onboard-old">
                <Onboard />
              </Route>
              <Route component={Main} isAuthenticated />
            </Switch>
          </Suspense>
        )}
      </Router>
      <AppDialogs dialogImplementationRef={dialogFnRef} />
      <AppSnackbars snackbarsImplementationRef={snackbarsImplementationRef} />
      <div style={{ position: 'fixed', bottom: '0', right: '0' }} ref={bottomrightRef} />
    </AppContext.Provider>
  );
}

function Plus(props) {
  const { isAuthenticated, user } = useAuth0();
  const { isAuthenticatedAndIsManager: isManager } = useAppContext();

  // Disable intercom for smaller devices
  if (!isAuthenticated && window.innerWidth <= 700 && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
  return (
    <Switch>
      <PrivateRoute component={Admin} authed={isAuthenticated} path="/admin" />
      <PrivateRoute component={ErrorSwitchTeam} authed={isManager} path="/plus/switchError" />
      <PrivatePlusRoute component={DrawReportPortal} authed={isManager} path="/plus/drawReports/:id" />
      <PrivatePlusRoute component={ProjectShell} authed={isManager} path="/plus/shell/:id" />
      <PrivatePlusRoute component={DashboardSwitch} authed={isManager} path="/plus/dashboard" />
      <PrivatePlusRoute component={BidRoom} authed={isManager} path="/plus/bidroom/:shellID/:projectID" />

      <PrivatePlusRoute
        component={ContractRoom}
        authed={isManager}
        path="/plus/contractroom/:shellID/:projectID/:selectedItemID"
      />
      <PrivatePlusRoute
        component={ContractRoom}
        authed={isManager}
        path="/plus/contractroom/:shellID/:projectID"
      />

      <PrivatePlusRoute component={Tasks} authed={isManager} path={['/plus/tasks/dv/:dvId', '/plus/tasks']} />

      <PrivatePlusRoute component={ErrorCenter} authed={isManager} path="/plus/errors" />
      <PrivatePlusRoute component={Settings} authed={isManager} path={['/plus/settings/:sideNav', '/plus/settings']} />
      <PrivatePlusRoute component={Reports} authed={isManager} path="/plus/reports" />
      <PrivatePlusRoute component={VendorDetail} authed={isManager} path="/plus/contractors/:contractorID" />
      <PrivatePlusRoute component={Vendors} authed={isManager} path="/plus/contractors" />
      <PrivatePlusRoute component={VendorSettings} authed vendorLogin path="/plus/vendors/settings" />
      <PrivatePlusRoute
        component={VendorProject}
        authed
        vendorLogin
        path="/plus/vendors/project/:projectID"
      />
      <PrivatePlusRoute component={VendorDashboard} authed vendorLogin path="/plus/vendors" />
      <PrivatePlusRoute component={DataValidation} authed={isManager} path="/plus/data" />
      <PrivatePlusRoute
        component={ApprovalPage}
        authed={isManager}
        path="/plus/approvals/:approvalPackageID"
      />
      <PrivatePlusRoute
        component={PlaidOnboardBuilding}
        authed={isManager}
        path="/plus/plaid-onboard-building/:buildingID"
      />
    </Switch>
  );
}

function Main(props) {
  const { isAuthenticated, user } = useAuth0();

  const isManager = isAuthenticated && hasManagerPermissions(user);

  // Disable intercom for smaller devices
  if (window.innerWidth <= 700 && window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }

  return (
    <React.Fragment>
      <div className={css.outer}>
        <Switch>
          <PrivateRoute component={LoginAs} authed path="/ops/loginAs/:contractorID/:projectID" />
          <Route path="/tos" render={(p) => <TermsOfUse edit {...p} />} />
          <PrivateRoute component={AdminProjectDetails} authed={isAuthenticated} path="/admin/projects/:id" />
          <PrivateRoute component={Admin} authed={isAuthenticated} path="/admin" />
        </Switch>
      </div>
    </React.Fragment>
  );
}
